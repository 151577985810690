import { DmsProviderType, Garage } from "src/states/serviceFlow/garageActions"

type UseIsOnlineAppointmentAvailable = (garage?: Garage) => boolean

const isOnlineAppointmentAvailable = (dmsProviderType?: DmsProviderType) => {
  return dmsProviderType === "PITSTOP" || dmsProviderType === "SFK"
}

export const useIsOnlineAppointmentAvailable: UseIsOnlineAppointmentAvailable =
  (garage) => {
    if (!garage) return false

    const isOnlineAppointment = isOnlineAppointmentAvailable(
      garage?.dmsProviderType,
    )
    return !!isOnlineAppointment
  }
