import Router from "next/router"
import { useEffect } from "react"

import useSWR from "swr/immutable"

import { getAditionalServicesIds } from "../../../checkout/helpers/getAditionalServicesIds"

import { useCheckoutSummaryTrackingEvent } from "../../../checkout/serviceCheckout/hooks/useCheckoutSummaryTrackingEvents"
import { ROUTES, serviceFlowSteps } from "src/config"

import { getIsWheelChangeOnlySelectedWithoutAdditional } from "src/helpers/getIsWheelChangeOnlySelected"
import { handleHttpError } from "src/helpers/http/handleHttpError"
import { performRequest } from "src/helpers/http/http"
import { HttpError } from "src/helpers/http/HttpError"
import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { useTranslation } from "src/hooks/translation"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import {
  addNotificationToast,
  removeAllNotificationToast,
} from "src/states/notificationToasts"
import {
  mergeServiceFlowData,
  useServiceFlowData,
} from "src/states/serviceFlow"
import garageActions from "src/states/serviceFlow/garageActions"
import { PromoCode } from "src/states/serviceFlow/promoCodeActions"
import {
  CoreApiPricesRequest,
  CoreApiPricesResponse,
} from "src/types/autoservice-core-backend"

export const inspectionCategoryServiceIds = {
  internalServiceId: "BASIC_INSPECTION",
  serviceCategoryId: "BASIC_INSPECTION",
}

type useGetPricesHook = () => {
  data?: {
    servicePrices: {
      internalServiceId: string
      priceAfterDiscount: number
      priceBeforeDiscount: number
      discountAmount: number
      additionalServices: {
        additionalServiceId: string
        priceAfterDiscount: number
        priceBeforeDiscount: number
        discountAmount: number
      }[]
    }[]
    priceAfterDiscount: number
    priceBeforeDiscount: number
    promoCode?: PromoCode
  }
  isLoading: boolean
  error?: HttpError
}

async function getPrices(pricesBody: CoreApiPricesRequest) {
  return performRequest<CoreApiPricesResponse>({
    method: "POST",
    path: ROUTES.api.core.services.prices,
    body: pricesBody,
  })
}

export const useGetPrices: useGetPricesHook = () => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.checkout.errors

  const {
    detailedGarage,
    serviceCategories,
    vehicle,
    promoCode,
    inspectionOfferId,
    inspectionAdditionalServices,
  } = useServiceFlowData()

  const { isInspectionCategorySelected, isInspectionOfferFlow } =
    useInspectionFlows()

  const isWheelChangeOnlySelectedWithoutAdditional =
    getIsWheelChangeOnlySelectedWithoutAdditional(serviceCategories)

  const { trackServiceUnavailable, trackAdditionalServiceUnavailable } =
    useCheckoutSummaryTrackingEvent()

  const serviceSelection = isInspectionOfferFlow
    ? [
        {
          ...inspectionCategoryServiceIds,
          additionalServicesRequest: inspectionAdditionalServices?.map(
            (additionalService) =>
              additionalService.internalAdditionalServiceId,
          ),
        },
      ]
    : serviceCategories?.map((service) => ({
        internalServiceId: service.service?.internalServiceId,
        serviceCategoryId: service.internalServiceCategoryId,
        additionalServicesRequest: getAditionalServicesIds([service]),
      }))

  const pricesRequestBody = {
    garageId: detailedGarage?.garage.id,
    hsn: vehicle?.hsn,
    tsn: vehicle?.tsn,
    promoCode: promoCode?.code,
    serviceSelection,
    typeId: vehicle?.engineId?.toString(),
    ...(isInspectionOfferFlow && { bookingId: inspectionOfferId }),
  }

  const isPitstop = isPitstopGarage(detailedGarage?.garage.dmsProviderType)
  const hasServices = serviceCategories && serviceCategories.length > 0
  const shouldFetchPricesforPWS =
    !isPitstop && isWheelChangeOnlySelectedWithoutAdditional

  // For inspection request flow we don't need to make api call here
  const shouldFetch =
    shouldFetchPricesforPWS ||
    (isPitstop && hasServices && !isInspectionCategorySelected) ||
    isInspectionOfferFlow

  const { data, error } = useSWR(
    shouldFetch ? pricesRequestBody : undefined,
    getPrices,
    {}, // Needed for useSWR lint
  )

  const handleServiceUnavailable = (
    services: string[],
    isAdditionalUnavailable = false,
  ) => {
    isAdditionalUnavailable
      ? trackAdditionalServiceUnavailable(services)
      : trackServiceUnavailable(services)
    Router.push(ROUTES.serviceFlow({ flowStep: serviceFlowSteps.garages }))
    removeAllNotificationToast()
    addNotificationToast({
      variant: "error",
      autoclose: true,
      autocloseTimeout: 10000,
      content: isAdditionalUnavailable
        ? translations.additionalServiceNotAvailable.message
        : translations.serviceNotAvailable.message,
    })
  }

  useEffect(() => {
    if (!!data?.servicesWhichCannotBeProvidedByGarage?.length) {
      return handleServiceUnavailable(
        data?.servicesWhichCannotBeProvidedByGarage,
      )
    }

    if (!!data?.additionalServicesWhichCannotBeProvidedByGarage?.length) {
      return handleServiceUnavailable(
        data?.additionalServicesWhichCannotBeProvidedByGarage,
        true,
      )
    }

    if (data) {
      garageActions.updateGarageTotalPrice(data.totalPrice)
    }

    mergeServiceFlowData((prevState) => {
      if (!data) {
        return prevState
      }
      return {
        ...prevState,
        priceAfterDiscount: data.priceAfterDiscount,
        priceBeforeDiscount: data.priceBeforeDiscount,
        discountAmount: data.discountAmount,
        servicePrices: data.servicePrices,
        detailedGarage: prevState?.detailedGarage
          ? {
              ...prevState.detailedGarage,
              priceAfterDiscount: data.priceAfterDiscount,
              priceBeforeDiscount: data.priceBeforeDiscount,
              discountAmount: data.discountAmount,
            }
          : undefined,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      handleHttpError({ error, messages })
    }
  }, [error, messages])

  return {
    data,
    isLoading: !error && !data,
    error: error,
  }
}
