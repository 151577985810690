import Router from "next/router"
import React, { FC, Fragment } from "react"

import { formatPriceInformation } from "../../helpers/formatPriceInformation"
import { ActionButtons } from "../actionButtons"
import { Separator } from "src/components/common/separator"
import { useShoppingCartTrackingEvents } from "src/components/core/shoppingCart/hooks/useShoppingCartTrackingEvents"
import { BookingSummaryCard } from "src/components/modules/bookingSummaryCard"
import { useCheckoutSummaryTrackingEvent } from "src/components/pages/services/checkout/serviceCheckout/hooks/useCheckoutSummaryTrackingEvents"
import { ServicePriceCalculationComponents } from "src/components/pages/services/checkout/serviceSummary/BookingSummary"
import { inspectionCategoryServiceIds } from "src/components/pages/services/flow/services/hooks/useGetPrices"
import { ROUTES } from "src/config"
import { BookingService } from "src/helpers/autoservice-account-backend/types"
import { create } from "src/helpers/bem"
import { getIsWheelChangeOnlySelectedWithoutAdditional } from "src/helpers/getIsWheelChangeOnlySelected"
import { getServiceNameById } from "src/helpers/getServiceNameById"
import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"
import { useNotifications } from "src/hooks/useNotifications"
import { useServiceFlowData } from "src/states/serviceFlow"

import { DetailedGarage } from "src/states/serviceFlow/garageActions"
import promoCodeActions from "src/states/serviceFlow/promoCodeActions"
import serviceCategoriesAction from "src/states/serviceFlow/serviceCategoriesAction"

import { InspectionAdditionalService } from "src/states/serviceFlow/types"

import { ServiceSection } from "./serviceSection"
import styles from "./ServicesSection.module.scss"

const bem = create(styles, "ServicesSection")

type ServicesSectionProps = {
  services?: BookingService[]
  garage?: DetailedGarage["garage"]
  isInspectionOfferFlowOrBooking?: boolean
  servicePriceCalculationComponents?: ServicePriceCalculationComponents
  isShoppingCart?: boolean
  onClose?: () => void
  priceBeforeDiscount?: number
  priceAfterDiscount?: number
  discountAmount?: number
}

export const ServicesSection: FC<ServicesSectionProps> = ({
  services,
  garage,
  isInspectionOfferFlowOrBooking,
  servicePriceCalculationComponents,
  isShoppingCart,
  onClose,
  priceBeforeDiscount,
  priceAfterDiscount,
  discountAmount,
}) => {
  const { messages, formatMessage } = useTranslation()
  const translations = messages.components.core.bookingSummaryCard

  const { notify } = useNotifications()

  const { flowStep, isInspectionOfferFlow } = useFlow()
  const {
    priceAfterDiscount: serviceFlowPriceAfterDiscount,
    priceBeforeDiscount: serviceFlowPriceBeforeDiscount,
    discountAmount: serviceFlowDiscountAmount,
    detailedGarage,
    serviceCategories,
    inspectionServiceComponents,
    inspectionAdditionalServices,
    servicePrices,
    promoCode,
  } = useServiceFlowData()

  const { trackEditServiceClick, trackDeleteServiceClick } =
    useCheckoutSummaryTrackingEvent()

  const {
    trackEditServiceClick: shoppingCartEditService,
    trackDeleteServiceClick: shoppingCartDeleteService,
  } = useShoppingCartTrackingEvents()

  function getPriceComponent(serviceId?: string) {
    return servicePriceCalculationComponents?.find(
      (service) => service.id === serviceId,
    )?.priceCalculationComponents
  }

  function getBookingServicesWithPrices(serviceId?: string) {
    return servicePrices?.find(
      (service) => service.internalServiceId === serviceId,
    )
  }

  const inspectionPriceCalculationComponents =
    getPriceComponent(inspectionCategoryServiceIds.serviceCategoryId) ??
    inspectionServiceComponents

  function onEditService(id?: string, serviceName: string = "") {
    return () => {
      isShoppingCart
        ? shoppingCartEditService(serviceName)
        : trackEditServiceClick(serviceName)

      if (isShoppingCart) {
        onClose?.()
      }
      const serviceRouteName = getServiceNameById(id)
      if (serviceRouteName) {
        Router.push(ROUTES.service({ serviceName: serviceRouteName }))
      }
    }
  }

  function onDeleteService(id?: string, serviceName: string = "") {
    return () => {
      isShoppingCart
        ? shoppingCartDeleteService(serviceName)
        : trackDeleteServiceClick(serviceName)

      serviceCategoriesAction.removeCategory(id)

      notify({
        variant: "success",
        content: translations.notifications.deleteServiceSucess,
        autoclose: true,
      })

      if (!!promoCode?.code) {
        promoCodeActions.clearPromoCode()

        notify({
          variant: "info",
          content: translations.notifications.promoCodeRemovalNotification,
          autoclose: true,
          autocloseTimeout: 10000,
        })
      }
    }
  }

  function getDeleteConfirmationModalProps(serviceName: string) {
    const { title, content, buttons } = translations.deleteConfirmation

    return {
      title: formatMessage(title, { serviceName }),
      content: formatMessage(content, { serviceName }),
      confirmButtonLabel: buttons.confirm,
    }
  }

  function getInspectionAdditionalServices(
    inspectionAdditionalServices?: InspectionAdditionalService[],
  ) {
    return inspectionAdditionalServices?.map(
      ({ internalAdditionalServiceId, germanDisplayName }) => {
        const additionalServicePrice =
          servicePrices?.[0]?.additionalServices.find(
            ({ additionalServiceId }) =>
              additionalServiceId === internalAdditionalServiceId,
          )
        return {
          id: internalAdditionalServiceId ?? "",
          name: germanDisplayName ?? "",
          priceAfterDiscount: additionalServicePrice?.priceAfterDiscount ?? 0,
          priceBeforeDiscount: additionalServicePrice?.priceBeforeDiscount ?? 0,
          discountAmount: additionalServicePrice?.discountAmount ?? 0,
        }
      },
    )
  }

  const isPitstop = isPitstopGarage(
    garage?.dmsProviderType ?? detailedGarage?.garage.dmsProviderType,
  )
  const canShowPricesForPWS = getIsWheelChangeOnlySelectedWithoutAdditional(
    serviceCategories ?? services,
  )

  const totalPaid = priceAfterDiscount ?? serviceFlowPriceAfterDiscount

  const formattedTotalPrice = !!totalPaid
    ? formatPriceInformation(totalPaid)
    : undefined

  const canShowPrice =
    (isPitstop && !!formattedTotalPrice) || canShowPricesForPWS

  const canEditOrDelete = isShoppingCart || (flowStep && !isInspectionOfferFlow)

  const getPriceInfo = () => {
    if (!canShowPrice) {
      return
    }
    return <span>{formattedTotalPrice}</span>
  }
  return (
    <BookingSummaryCard title={translations.title} value={getPriceInfo()}>
      <div className={bem("services")}>
        {isInspectionOfferFlowOrBooking ? (
          <ServiceSection
            service={{
              id: "BASIC_INSPECTION",
              name: translations.inspection.title,
              icon: "basisinspektion",
              priceAfterDiscount:
                priceAfterDiscount ??
                serviceFlowPriceAfterDiscount ??
                services?.[0]?.priceAfterDiscount ??
                0,
              priceBeforeDiscount:
                priceBeforeDiscount ??
                serviceFlowPriceBeforeDiscount ??
                services?.[0]?.priceBeforeDiscount ??
                0,
              discountAmount:
                discountAmount ??
                serviceFlowDiscountAmount ??
                services?.[0]?.discountAmount ??
                0,
              additionalServices:
                getInspectionAdditionalServices(inspectionAdditionalServices) ??
                services?.[0].additionalServices,
            }}
            priceCalculationComponents={inspectionPriceCalculationComponents}
            isInspectionOfferFlow
            canShowPrice={canShowPrice}
            garage={detailedGarage?.garage ?? garage}
          />
        ) : (
          services?.map((service) => {
            return (
              <Fragment key={service.id}>
                <div
                  className={bem("service")}
                  data-cy={`category-${
                    service.internalServiceId || service.id
                  }`}
                >
                  <ServiceSection
                    service={service}
                    priceCalculationComponents={getPriceComponent(
                      service.internalServiceId || service.id,
                    )}
                    servicePrice={getBookingServicesWithPrices(
                      service.internalServiceId || service.id,
                    )}
                    garage={detailedGarage?.garage ?? garage}
                    isShoppingCart={isShoppingCart}
                    canShowPrice={canShowPrice}
                  />
                  {canEditOrDelete && (
                    <ActionButtons
                      onEdit={onEditService(service.categoryId, service.name)}
                      onDelete={onDeleteService(
                        service.categoryId,
                        service.name,
                      )}
                      deleteConfirmationModal={getDeleteConfirmationModalProps(
                        service.name,
                      )}
                    />
                  )}
                </div>

                <Separator variant="alternative" />
              </Fragment>
            )
          })
        )}
      </div>
    </BookingSummaryCard>
  )
}
