import { format } from "date-fns/format"
import Router from "next/router"
import { FC } from "react"

import { useShoppingCartTrackingEvents } from "src/components/core/shoppingCart/hooks/useShoppingCartTrackingEvents"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { useCheckoutSummaryTrackingEvent } from "src/components/pages/services/checkout/serviceCheckout/hooks/useCheckoutSummaryTrackingEvents"
import { ROUTES, serviceFlowSteps } from "src/config"
import { create } from "src/helpers/bem"
import { dateFnsLocale } from "src/helpers/dateLocales"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"
import shoppingCartActions from "src/states/shoppingCart/shoppingCartActions"
import { formatters } from "src/translations/formatters"

import styles from "./AppointmentSummaryCardItem.module.scss"

const bem = create(styles, "AppointmentSummaryCardItem")

type AppointmentSummaryCardItemProps = {
  title: string
  timeslot?: Date | null
  isExpressAppointmentSelected?: boolean
}

export const AppointmentSummaryCardItem: FC<
  AppointmentSummaryCardItemProps
> = ({ title, timeslot, isExpressAppointmentSelected }) => {
  const { messages, formatMessage } = useTranslation()
  const translations =
    messages.components.service.steps.summary.appointmentSelection.appointment

  const { flow, flowStep } = useFlow()

  const isShoppingCart = shoppingCartActions.isOpen()

  const { trackEditAppointmentClick } = useCheckoutSummaryTrackingEvent()

  const { trackEditAppointmentClick: shoppingCartEditAppointment } =
    useShoppingCartTrackingEvents()

  if (!timeslot) {
    return null
  }

  function onEditAppointment() {
    isShoppingCart ? shoppingCartEditAppointment() : trackEditAppointmentClick()
    const flowStep = isShoppingCart
      ? serviceFlowSteps.garages
      : serviceFlowSteps.checkout

    shoppingCartActions.close()

    Router.push(
      ROUTES.serviceFlow({
        flowStep,
        flow,
        booleanParam: "openAppointments",
      }),
      undefined,
      { shallow: true },
    )
  }

  const formattedDate =
    !!timeslot &&
    formatMessage(translations.dateFormat, {
      selectedDate: timeslot,
      fullDayName: format(timeslot, formatters.date.fullDay, {
        locale: dateFnsLocale,
      }),
    })

  const formattedTime =
    !!timeslot &&
    formatMessage(translations.timeFormat, {
      time: timeslot,
    })

  const timeTitle = (
    <div className={bem()}>
      <div>
        <strong>{title}</strong> {formattedTime}
      </div>

      <div className={bem("time__description")}>
        {isExpressAppointmentSelected
          ? translations.description.express
          : translations.description.dropOff}
      </div>
    </div>
  )

  return (
    <>
      <BookingSummaryCardItem
        icon="calendar"
        title={formattedDate}
        onEdit={!!flowStep || isShoppingCart ? onEditAppointment : undefined}
        dataCy="SummaryItemCard-calendar"
      />
      <BookingSummaryCardItem icon="timer" title={timeTitle} />
    </>
  )
}
