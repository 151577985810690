import { FC } from "react"

import { formatPriceInformation } from "../../../helpers/formatPriceInformation"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { BookingService } from "src/helpers/autoservice-account-backend/types"
import { create } from "src/helpers/bem"
import { getTotalServicePrice } from "src/helpers/getTotalServicePrice"
import { isPWSGarage } from "src/helpers/isPWSGarage"
import { useTranslation } from "src/hooks/translation"

import { DetailedGarage } from "src/states/serviceFlow/garageActions"

import { ServicePrice } from "src/states/serviceFlow/types"

import styles from "./Service.module.scss"

const bem = create(styles, "Service")

type ServiceProps = {
  canShowPrice?: boolean
  service?: BookingService
  servicePrice?: ServicePrice
  garage?: DetailedGarage["garage"]
  isInspectionRequestFlow?: boolean
  isInspectionOfferFlow?: boolean
}

export const Service: FC<ServiceProps> = ({
  service,
  garage,
  servicePrice,
  isInspectionRequestFlow,
  isInspectionOfferFlow,
  canShowPrice,
}) => {
  const { messages } = useTranslation()

  const isPWS = isPWSGarage(garage?.dmsProviderType)

  const translations = messages.components.core.bookingSummaryCard
  const priceOnDemandText =
    messages.components.service.steps.summary.onDemand.price

  const getTotalPrice = () => {
    if (isInspectionOfferFlow) {
      return {
        priceBeforeDiscount: formatPriceInformation(
          service?.priceBeforeDiscount,
        ),
        priceAfterDiscount: formatPriceInformation(service?.priceAfterDiscount),
        discountAmount: service?.discountAmount,
      }
    }

    if (!canShowPrice || !garage) {
      return
    }

    const totalPriceForService = getTotalServicePrice(servicePrice ?? service)
    const totalPriceBeforeDiscount = formatPriceInformation(
      totalPriceForService?.mainServicePriceBeforeDiscount,
    )
    const totalPriceAfterDiscount = formatPriceInformation(
      totalPriceForService?.mainServicePriceAfterDiscount,
    )

    return {
      priceBeforeDiscount: totalPriceBeforeDiscount,
      priceAfterDiscount: totalPriceAfterDiscount,
      discountAmount: service?.discountAmount,
    }
  }

  const totalPrice = getTotalPrice()
  const showPriceOnDemand =
    isPWS && !canShowPrice && !!garage && !isInspectionRequestFlow

  const getPriceInfo = () => {
    if (!!service?.discountAmount) {
      return (
        <>
          <span className={bem("originalPrice")}>
            {totalPrice?.priceBeforeDiscount}
          </span>
          <span data-cy="ServicePrice">{totalPrice?.priceAfterDiscount}</span>
        </>
      )
    }
    return <span data-cy="ServicePrice">{totalPrice?.priceAfterDiscount}</span>
  }
  return (
    <>
      <BookingSummaryCardItem
        title={service?.name}
        icon={service?.icon}
        variant="bold"
        rightInfo={getPriceInfo()}
      />
      {!totalPrice && !garage && (
        <BookingSummaryCardItem
          title={
            <div className={bem("priceText")}>{translations.noPriceText}</div>
          }
        />
      )}
      {showPriceOnDemand && (
        <BookingSummaryCardItem
          title={<div className={bem("priceText")}>{priceOnDemandText}</div>}
        />
      )}
    </>
  )
}
