import { ServiceCategory } from "src/states/serviceFlow/types"

export const getAditionalServicesIds = (
  categories: ServiceCategory[] | undefined,
): string[] => {
  const additionalServicesIds: string[] = []

  if (categories) {
    categories.forEach((category) => {
      category.additionalServices.forEach((additionalService) => {
        if (!!additionalService.internalAdditionalServiceId) {
          additionalServicesIds.push(
            additionalService.internalAdditionalServiceId,
          )
        }
      })
    })
  }

  return additionalServicesIds
}
