import Router from "next/router"
import { FC } from "react"

import { useShoppingCartTrackingEvents } from "src/components/core/shoppingCart/hooks/useShoppingCartTrackingEvents"

import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { useCheckoutSummaryTrackingEvent } from "src/components/pages/services/checkout/serviceCheckout/hooks/useCheckoutSummaryTrackingEvents"
import { GarageRatings } from "src/components/pages/services/flow/garages/GarageDetails/GarageRatings/GarageRatings"
import { ROUTES, serviceFlowSteps } from "src/config"
import { useFlow } from "src/hooks/useFlow"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import { Garage } from "src/states/serviceFlow/garageActions"
import shoppingCartActions from "src/states/shoppingCart/shoppingCartActions"

type GarageSummaryCardItemProps = {
  garage?: Garage
}

export const GarageSummaryCardItem: FC<GarageSummaryCardItemProps> = ({
  garage,
}) => {
  const { flow, flowStep } = useFlow()
  const { isInspectionOfferFlow } = useInspectionFlows()

  const isShoppingCart = shoppingCartActions.isOpen()

  const { trackEditGarageClick } = useCheckoutSummaryTrackingEvent()

  const { trackEditGarageClick: shoppingCartEditGarage } =
    useShoppingCartTrackingEvents()

  if (!garage) {
    return null
  }

  function onEditGarage() {
    isShoppingCart ? shoppingCartEditGarage() : trackEditGarageClick()

    shoppingCartActions.close()

    Router.push(
      ROUTES.serviceFlow({ flowStep: serviceFlowSteps.garages, flow }),
    )
  }

  const garageTitle = garage && (
    <div>
      <div>{garage.name}</div>
      <div>{!!garage.street && `${garage.street}, `}</div>
      <div>
        {!!garage.postalCode && `${garage.postalCode}, `}
        {garage.city}
      </div>
      <GarageRatings npsRating={garage.reviewCalculations} />
    </div>
  )

  return (
    <BookingSummaryCardItem
      icon="pinSummary"
      title={garageTitle}
      onEdit={
        isShoppingCart || (!!flowStep && !isInspectionOfferFlow)
          ? onEditGarage
          : undefined
      }
      dataCy="SummaryItemCard-garage"
    />
  )
}
