import { FC, useState } from "react"

import { Icon } from "src/components/common/icon"
import { ServiceInquiryModal } from "src/components/pages/services/flow/services/serviceInquiry/serviceInquiryForm/ServiceInquiryModal"

import { create } from "src/helpers/bem"
import { isPWSGarage } from "src/helpers/isPWSGarage"
import { useTranslation } from "src/hooks/translation"

import { useServiceFlowData } from "src/states/serviceFlow"

import styles from "./AddInquiry.module.scss"

const bem = create(styles, "AddInquiry")

export const AddInquiry: FC = () => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.completed.inquiry

  const { inquiry, detailedGarage } = useServiceFlowData()

  const [openInquiryModal, setOpenInquiryModal] = useState(false)

  const hasInquiry = !!inquiry
  const isPWSSelected =
    !!detailedGarage && isPWSGarage(detailedGarage?.garage?.dmsProviderType)

  if (hasInquiry || !isPWSSelected) return null

  const onClick = () => {
    setOpenInquiryModal(true)
  }

  return (
    <>
      <div
        onClick={onClick}
        className={bem("add-inquiry")}
        data-cy="add-inquiry"
      >
        <div className={bem("content")}>
          <Icon name="notes" className={bem("icon")} />
          <p>{translations.button}</p>
        </div>
        <Icon name="navNext" className={bem("nav-icon")} />
      </div>
      <ServiceInquiryModal
        open={openInquiryModal}
        inquiry={inquiry}
        canEdit={true}
        onOpenChange={() => setOpenInquiryModal(false)}
        setDefaultSubject
      />
    </>
  )
}
