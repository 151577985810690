import { FC, useState } from "react"

import {
  DeleteConfirmationModal,
  DeleteConfirmationModalLabels,
} from "src/components/common/modal/deleteConfirmationModal"

import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"

type ActionButtonsProps = {
  onEdit: () => void
  onDelete: () => void
  sectionName?: string
  deleteConfirmationModal?: DeleteConfirmationModalLabels
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  onEdit,
  onDelete,
  sectionName,
  deleteConfirmationModal,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  function onDeleteButtonClick() {
    if (deleteConfirmationModal) {
      return setShowDeleteConfirmation(true)
    }

    onDelete()
  }

  return (
    <>
      <BookingSummaryCardItem
        onEdit={onEdit}
        onDelete={onDeleteButtonClick}
        sectionName={sectionName}
      />

      {!!deleteConfirmationModal && showDeleteConfirmation && (
        <DeleteConfirmationModal
          isModalOpen
          onOpenModalChange={setShowDeleteConfirmation}
          onConfirm={onDelete}
          {...deleteConfirmationModal}
          confirmationDataCy="confirm-service-delete"
        />
      )}
    </>
  )
}
