import { FC, ReactNode } from "react"

import { Icon, IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import styles from "./BookingSummaryCardItem.module.scss"

const bem = create(styles, "BookingSummaryCardItem")

export type BookingSummaryCardItemProps = {
  title?: ReactNode
  icon?: IconName
  variant?: "default" | "bold" | "alternate"
  rightInfo?: ReactNode
  sectionName?: string
  onEdit?: () => void
  onDelete?: () => void
  dataCy?: string
}

export const BookingSummaryCardItem: FC<BookingSummaryCardItemProps> = ({
  title,
  icon,
  variant = "default",
  rightInfo,
  sectionName,
  onEdit,
  onDelete,
  dataCy,
}) => {
  return (
    <div className={bem()} data-cy={dataCy}>
      <div className={bem("left")}>
        {icon && <Icon className={bem("icon")} name={icon} />}
      </div>

      <div className={bem("right", { [variant]: true })}>
        <div className={bem("title")}>{title}</div>

        <div className={bem("info")}>
          {rightInfo}

          {onEdit && (
            <button
              className={bem("icon-wrapper")}
              onClick={onEdit}
              data-cy={
                sectionName ? `${sectionName}-edit-button` : "edit-button"
              }
            >
              <Icon className={bem("icon", { action: true })} name="edit" />
            </button>
          )}

          {onDelete && (
            <button
              className={bem("icon-wrapper")}
              onClick={onDelete}
              data-cy={
                sectionName ? `${sectionName}-delete-button` : "delete-button"
              }
            >
              <Icon className={bem("icon", { action: true })} name="trash" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
