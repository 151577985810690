import { FC, PropsWithChildren, ReactNode, useState } from "react"

import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import styles from "./BookingSummaryCard.module.scss"

const bem = create(styles, "BookingSummaryCard")

export type BookingSummaryCardProps = PropsWithChildren<{
  title: string
  value?: ReactNode
  sectionName?: string
  collapsible?: boolean
}>

export const BookingSummaryCard: FC<BookingSummaryCardProps> = ({
  title,
  value,
  sectionName,
  collapsible,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!collapsible)

  return (
    <div className={bem()}>
      <div className={bem("header")}>
        <h3 className={bem("title")}>{title}</h3>

        <div className={bem("header__right")}>
          <div data-cy={`${sectionName}-value`}>{value}</div>

          {collapsible && (
            <button
              className={bem("collapse-button")}
              onClick={() => setIsCollapsed(!isCollapsed)}
              data-cy="collapsibleButton"
            >
              <Icon
                className={bem("collapse-icon", { collapsed: isCollapsed })}
                name="dropdownMenuUp"
              />
            </button>
          )}
        </div>
      </div>

      {children && (
        <div className={bem("content", { collapsed: isCollapsed })}>
          {children}
        </div>
      )}
    </div>
  )
}
