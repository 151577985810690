import { FC } from "react"

import { DeleteConfirmationModalLabels } from "src/components/common/modal/deleteConfirmationModal"

import { Separator } from "src/components/common/separator"
import { ActionButtons } from "src/components/core/bookingSummaryCard/sections/actionButtons"
import { BookingSummaryCard } from "src/components/modules/bookingSummaryCard"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"

import inquiryActions, { Inquiry } from "src/states/serviceFlow/inquiryActions"

import styles from "./InquirySection.module.scss"

const bem = create(styles, "InquirySection")

export type InquirySectionProps = {
  openModal: () => void
  inquiry?: Inquiry
  isShoppingCart?: boolean
}

export const InquirySection: FC<InquirySectionProps> = ({
  inquiry,
  openModal,
  isShoppingCart,
}) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.completed.inquiry
  const { flowStep } = useFlow()

  const showActionButtons = isShoppingCart || !!flowStep // flowStep is undefined when out of Flow

  if (!inquiry) return null

  const { message, subject } = inquiry

  const { title, content, confirm } = translations.deleteConfirmation

  const deleteConfirmationModal: DeleteConfirmationModalLabels = {
    title,
    content,
    confirmButtonLabel: confirm,
  }

  return (
    <BookingSummaryCard title={translations.modal.title}>
      <div>
        <BookingSummaryCardItem
          icon="notes"
          sectionName="inquiry"
          title={subject}
          variant="bold"
          dataCy="SummaryItemCard-inquiry"
        />
        <BookingSummaryCardItem
          title={<div className={bem("truncate")}>{message}</div>}
          dataCy="SummaryItemCard-inquiry-message"
        />
        {showActionButtons && (
          <ActionButtons
            onEdit={openModal}
            onDelete={inquiryActions.clearInquiry}
            sectionName="inquiry"
            deleteConfirmationModal={deleteConfirmationModal}
          />
        )}
      </div>
      <Separator variant="alternative" />
    </BookingSummaryCard>
  )
}
