import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon, IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import styles from "./ActionButton.module.scss"

const bem = create(styles, "ActionButton")

type ActionButtonType = "edit" | "delete"

type ActionButtonProps = {
  type: ActionButtonType
  onClick: () => void
  dataCy?: string
}

export const ActionButton: FC<ActionButtonProps> = ({
  type,
  onClick,
  dataCy,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.service.steps.summary.actionButtons

  const actionButtonTypes: {
    [key in ActionButtonType]: { text: string; icon: IconName }
  } = {
    edit: {
      text: translations.edit,
      icon: "edit",
    },
    delete: {
      text: translations.delete,
      icon: "trash",
    },
  }

  const action = actionButtonTypes[type]

  return (
    <>
      <Button
        onClick={onClick}
        className={bem("button")}
        variant="tertiary"
        data-cy={dataCy}
      >
        <Icon className={bem("icon")} name={action.icon} /> {action.text}
      </Button>
    </>
  )
}
