import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useShoppingCartTrackingEvents = () => {
  function trackOpenShoppingCart() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "open_shopping_cart",
      },
    })
  }

  function trackCloseShoppingCart() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "close_shopping_cart",
      },
    })
  }

  function trackOpenCloseShoppingCart(open: boolean) {
    open ? trackOpenShoppingCart() : trackCloseShoppingCart()
  }

  function trackGoToServiceSelection() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "cta_go_to_service_selection",
      },
    })
  }

  function trackMostBookedServiceClick(name: string) {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "most_booked_service_click",
        label: name,
      },
    })
  }

  function trackEditServiceClick(name: string) {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "edit_service_click",
        label: name,
      },
    })
  }

  function trackDeleteServiceClick(name: string) {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "delete_service_click",
        label: name,
      },
    })
  }

  function trackEditCarClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "edit_car_click",
      },
    })
  }

  function trackEditGarageClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "edit_garage_click",
      },
    })
  }

  function trackEditAppointmentClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "edit_appointment_click",
      },
    })
  }

  function trackContinueToNextStepClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "cta_continue_to_next_step",
      },
    })
  }

  function trackBackButtonClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "click_back_button",
      },
    })
  }

  function trackSelectedServiceClick(name: string) {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "selected_service_click",
        label: name,
      },
    })
  }

  function trackFAQClick() {
    sendTrackingEvent.shoppingCart({
      gaEvent: {
        action: "faq_section_click",
      },
    })
  }

  return {
    trackOpenCloseShoppingCart,
    trackGoToServiceSelection,
    trackMostBookedServiceClick,
    trackEditServiceClick,
    trackDeleteServiceClick,
    trackEditCarClick,
    trackEditGarageClick,
    trackEditAppointmentClick,
    trackContinueToNextStepClick,
    trackBackButtonClick,
    trackSelectedServiceClick,
    trackFAQClick,
  }
}
