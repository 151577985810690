import Router from "next/router"
import { FC } from "react"

import { useShoppingCartTrackingEvents } from "src/components/core/shoppingCart/hooks/useShoppingCartTrackingEvents"

import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { useCheckoutSummaryTrackingEvent } from "src/components/pages/services/checkout/serviceCheckout/hooks/useCheckoutSummaryTrackingEvents"
import { ROUTES, serviceFlowSteps } from "src/config"
import { CarBookingInfo } from "src/helpers/autoservice-account-backend/types"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import shoppingCartActions from "src/states/shoppingCart/shoppingCartActions"

type CarSummaryCardItemProps = {
  car?: Partial<Omit<CarBookingInfo, "id">>
}

export const CarSummaryCardItem: FC<CarSummaryCardItemProps> = ({ car }) => {
  const { messages } = useTranslation()
  const translations =
    messages.components.service.steps.summary.appointmentSelection.car

  const { flow, flowStep } = useFlow()
  const { isInspectionOfferFlow } = useInspectionFlows()

  const { trackEditCarClick } = useCheckoutSummaryTrackingEvent()
  const isShoppingCart = shoppingCartActions.isOpen()

  const { trackEditCarClick: shoppingCartEditCar } =
    useShoppingCartTrackingEvents()

  if (!car) {
    return null
  }

  function onEditCar() {
    isShoppingCart ? shoppingCartEditCar() : trackEditCarClick()

    shoppingCartActions.close()

    Router.push(
      ROUTES.serviceFlow({ flowStep: serviceFlowSteps.vehicle, flow }),
    )
  }

  const carTitle = car && (
    <div>
      <div>
        {car.model ||
          `${translations.hsn}: ${car.hsn} | ${translations.tsn}: ${car.tsn}`}
      </div>
      {!!car.licensePlate && <div>{car.licensePlate}</div>}
    </div>
  )

  return (
    <BookingSummaryCardItem
      icon="car"
      title={carTitle}
      onEdit={
        isShoppingCart || (!!flowStep && !isInspectionOfferFlow)
          ? onEditCar
          : undefined
      }
      dataCy="SummaryItemCard-car"
    />
  )
}
