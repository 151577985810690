import { FC, ReactNode } from "react"

import { Button } from "../../button"
import { Modal, ModalContent } from "src/components/common/modal"

import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./DeleteConfirmationModal.module.scss"

const bem = create(styles, "DeleteConfirmationModal")

export type DeleteConfirmationModalLabels = {
  title: string
  content: ReactNode
  confirmButtonLabel: string
}

type DeleteConfirmationModalProps = DeleteConfirmationModalLabels & {
  isModalOpen: boolean
  onOpenModalChange: (isModalOpen: boolean) => void
  onConfirm: () => void
  hideButtons?: boolean
  confirmationDataCy?: string
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  isModalOpen,
  onOpenModalChange,
  onConfirm,
  title,
  content,
  confirmButtonLabel,
  hideButtons,
  confirmationDataCy,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.common.modal.deleteConfirmationModal

  const showButtons = hideButtons !== true

  return (
    <Modal open={isModalOpen} onOpenChange={onOpenModalChange}>
      {isModalOpen && (
        <ModalContent variant="sm" title={title} bodyClassName={bem("content")}>
          {content}

          {showButtons && (
            <div className={bem("buttons")}>
              <Button
                size="lg"
                variant="danger"
                onClick={onConfirm}
                data-cy={confirmationDataCy}
              >
                {confirmButtonLabel}
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => onOpenModalChange(false)}
              >
                {translations.buttons.cancel}
              </Button>
            </div>
          )}
        </ModalContent>
      )}
    </Modal>
  )
}
