import { FC } from "react"

import { ServicePrice as ServicePriceComponent } from "../servicePrice"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { BookingService } from "src/helpers/autoservice-account-backend/types"
import { PriceCalculationComponents } from "src/helpers/autoservice-core-backend/types"

import { ServicePrice } from "src/states/serviceFlow/types"

import { useServiceSummaryPriceData } from "./hooks/useServiceSummaryPriceData"

type ServiceSectionPriceComponentsProps = {
  service?: BookingService
  servicePrice?: ServicePrice
  isPWSGarage?: boolean
  priceCalculationComponents?: PriceCalculationComponents
}

export const ServiceSectionPriceComponents: FC<
  ServiceSectionPriceComponentsProps
> = ({ service, servicePrice, isPWSGarage, priceCalculationComponents }) => {
  const serviceSummaryPriceData = useServiceSummaryPriceData({
    id: service?.categoryId ?? service?.id,
    service,
    servicePrice,
    priceCalculationComponents,
    isPWSGarage,
  })

  return serviceSummaryPriceData.map((service, index) => (
    <BookingSummaryCardItem
      dataCy={`additional-service-${service.id}`}
      key={index}
      variant="alternate"
      title={service.title}
      rightInfo={<ServicePriceComponent price={service.price} />}
    />
  ))
}
