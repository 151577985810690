import { mergeServiceFlowData } from "./serviceFlow"

export type PromoCode = {
  code?: string
  value?: number
  description?: string
  discountType?: "FIXED_AMOUNT" | "PERCENTAGE" | "OVERRIDE_PRICE" | "NONE"
}

const promoCodeActions = {
  clearPromoCode: () => {
    mergeServiceFlowData({ promoCode: undefined })
  },

  setPromoCode: (promoCode?: PromoCode) => {
    if (!promoCode?.code) {
      return
    }

    mergeServiceFlowData({
      promoCode,
    })
  },

  setPromoCodeValue: (code: PromoCode["code"]) => {
    mergeServiceFlowData({
      promoCode: {
        code,
      },
    })
  },
}

export default promoCodeActions
