import { FC } from "react"

import { formatPriceInformation } from "../../../helpers/formatPriceInformation"
import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./ServicePrice.module.scss"

const bem = create(styles, "ServicePrice")

type ServicePriceProps = {
  price?: number | null
}

export const ServicePrice: FC<ServicePriceProps> = ({ price }) => {
  const { messages } = useTranslation()
  const translations = messages.components.service.steps.summary

  const formattedInformation = formatPriceInformation(price)

  return (
    <div className={bem()}>
      <span>{formattedInformation}</span>

      {formattedInformation === translations.free && (
        <Icon name="geschenk" className={bem("icon")} />
      )}

      {formattedInformation === translations.included && (
        <Icon name="inklusive" className={bem("icon")} />
      )}
    </div>
  )
}
