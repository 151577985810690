import { ServicePrice } from "src/states/serviceFlow/types"

import { BookingService } from "./autoservice-account-backend/types"

type TotalPriceType = {
  mainServicePriceBeforeDiscount: number
  mainServicePriceAfterDiscount: number
}
export const getTotalServicePrice = (
  service?: ServicePrice | BookingService,
): TotalPriceType | undefined => {
  if (!service?.priceAfterDiscount) {
    return
  }

  const mainServicePriceBeforeDiscount = service?.priceBeforeDiscount ?? 0
  const mainServicePriceAfterDiscount = service?.priceAfterDiscount ?? 0

  const additionalServicesPrice =
    service?.additionalServices?.reduce(
      (totalPrice, service) => totalPrice + (service.priceAfterDiscount ?? 0),
      0,
    ) ?? 0

  return {
    mainServicePriceBeforeDiscount:
      mainServicePriceBeforeDiscount + additionalServicesPrice,
    mainServicePriceAfterDiscount:
      mainServicePriceAfterDiscount + additionalServicesPrice,
  }
}
