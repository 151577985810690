import { FC } from "react"

import { Icon } from "src/components/common/icon"
import { GoogleRating } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"

import { CoreApiGarageNpsResponse } from "src/types/autoservice-core-backend"

import styles from "./GarageRatings.module.scss"

const bem = create(styles, "GarageRatings")

export type GarageRatingsProps = {
  npsRating?: CoreApiGarageNpsResponse
  googleRating?: GoogleRating
}

export const GarageRatings: FC<GarageRatingsProps> = ({
  npsRating,
  googleRating,
}) => {
  if (!npsRating && !googleRating) return null

  const { rating, userRatingsTotal } = googleRating || {}
  const { averageGarageScore, numberOfReviewsWithGarageScore } = npsRating || {}

  return (
    <div>
      {averageGarageScore && (
        <div className={bem("details")}>
          <Icon className={bem("icon")} name="star" />
          {`${averageGarageScore.toFixed(1)} (${numberOfReviewsWithGarageScore})`}
        </div>
      )}
      {rating && (
        <div className={bem("details")}>
          <Icon className={bem("icon")} name="google" />
          {`${rating.toFixed(1)} (${userRatingsTotal})`}
        </div>
      )}
    </div>
  )
}
