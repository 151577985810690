import { LoginType } from "../../CheckoutModal"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useCheckoutSummaryTrackingEvent = () => {
  function trackEditServiceClick(name: string) {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "edit_service_click",
        label: name,
      },
    })
  }

  function trackDeleteServiceClick(name: string) {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "delete_service_click",
        label: name,
      },
    })
  }

  function trackEditCarClick() {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "edit_car_click",
      },
    })
  }

  function trackEditGarageClick() {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "edit_garage_click",
      },
    })
  }

  function trackEditAppointmentClick() {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "edit_appointment_click",
      },
    })
  }

  function trackServiceUnavailable(serviceList: string[]) {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "service_unavailable",
        label: serviceList.join(","),
      },
    })
  }

  function trackAdditionalServiceUnavailable(serviceList: string[]) {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "additional_service_unavailable",
        label: serviceList.join(","),
      },
    })
  }

  function trackCheckInType(type: LoginType) {
    sendTrackingEvent.checkin({
      gaEvent: {
        action: "checkin_type",
        label: type,
      },
    })
  }

  function trackPromoCodeDelete() {
    sendTrackingEvent.checkoutSummary({
      gaEvent: {
        action: "promocode_delete",
      },
    })
  }

  return {
    trackEditServiceClick,
    trackDeleteServiceClick,
    trackEditCarClick,
    trackEditGarageClick,
    trackEditAppointmentClick,
    trackServiceUnavailable,
    trackCheckInType,
    trackPromoCodeDelete,
    trackAdditionalServiceUnavailable,
  }
}
