import { openNotificationModal } from "src/states/notificationModal"
import { addNotificationToast } from "src/states/notificationToasts"
import { TranslationMessages } from "src/translations"

import { HttpError } from "./HttpError"

function showDefaultError(messages: TranslationMessages) {
  addNotificationToast({
    variant: "error",
    autoclose: true,
    content: messages.helpers.autoservice.error.fallback.content,
  })
}

function showConnectionError(messages: TranslationMessages) {
  addNotificationToast({
    variant: "error",
    autoclose: true,
    content: messages.helpers.autoservice.error.fallback.content,
  })
}

function showConnectionErrorDialog(
  messages: TranslationMessages,
  retryFn: () => any,
) {
  openNotificationModal({
    title: messages.helpers.autoservice.error.timeout.title,
    content: messages.helpers.autoservice.error.timeout.content,
    buttons: [
      {
        variant: "primary",
        label: messages.helpers.autoservice.error.timeout.retry,
        background: true,
        onClick: retryFn,
      },
      {
        variant: "tertiary",
        label: messages.helpers.autoservice.error.timeout.cancel,
      },
    ],
  })
}

export type ErrorHandlerSettings = {
  /**
   * The error instance that should be handled.
   */
  error: unknown

  /**
   * The translation messages that are required to display a notification.
   */
  messages: TranslationMessages

  /**
   * An optional function to retry the previously errored operation on user
   * interaction. When setting this, the notification may vary depending on
   * error type and state.
   */
  onRetry?: () => any
}

/**
 * Helper function to use with try/catch-clause to handle any connection errors.
 * This will display a notification that matches the current error state.
 * See: `HttpError.status`
 */
export function handleHttpError({
  error,
  messages,
  onRetry,
}: ErrorHandlerSettings) {
  if (error instanceof HttpError) {
    if (error.status === "REQUEST_TIMEOUT") {
      onRetry
        ? showConnectionErrorDialog(messages, onRetry)
        : showConnectionError(messages)
      return
    }
  }

  showDefaultError(messages)
}
