import { BookingService } from "src/helpers/autoservice-account-backend/types"
import { PriceCalculationComponents } from "src/helpers/autoservice-core-backend/types"
import { useGetContentfulServicesData } from "src/hooks/useGetContentfulServicesData"
import { ServicePrice } from "src/states/serviceFlow/types"

type ServiceSummaryPriceData = {
  title?: string
  price?: number | null | undefined
  id?: string
}

type UseServiceSummaryPriceData = (props: {
  id?: string
  service?: BookingService
  servicePrice?: ServicePrice
  priceCalculationComponents?: PriceCalculationComponents
  isPWSGarage?: boolean
}) => ServiceSummaryPriceData[]

export const useServiceSummaryPriceData: UseServiceSummaryPriceData = ({
  id,
  service,
  servicePrice,
  priceCalculationComponents,
  isPWSGarage,
}) => {
  const { categoriesData } = useGetContentfulServicesData()

  const servicePrices: ServiceSummaryPriceData[] = []
  if (!!priceCalculationComponents?.length) {
    priceCalculationComponents.forEach((priceComponent) => {
      servicePrices.push({
        title: priceComponent.name,
        price: priceComponent.totalAmountPaid,
      })
    })
  }

  if (!!service?.packageAdditionalServiceIds?.length && categoriesData) {
    const categoryData = categoriesData.find(
      (category) => category.internalServiceCategoryId === id,
    )

    service.packageAdditionalServiceIds.forEach((id) => {
      const additionalServiceData = categoryData?.additionalServices.find(
        (additionalService) =>
          additionalService.internalAdditionalServiceId === id,
      )

      if (additionalServiceData) {
        servicePrices.push({
          title: additionalServiceData.title || "",
          price: null,
        })
      }
    })
  }

  if (servicePrice?.additionalServices) {
    servicePrice.additionalServices.forEach((additionalService) => {
      const additionalServices = service?.additionalServices?.find(
        (addService) => addService.id === additionalService.additionalServiceId,
      )
      servicePrices.push({
        id: additionalService.additionalServiceId,
        title: additionalServices?.name,
        price: !isPWSGarage ? additionalService.priceAfterDiscount : undefined,
      })
    })
  }

  if (!servicePrice && service?.additionalServices) {
    service.additionalServices.forEach((additionalService) => {
      servicePrices.push({
        id: additionalService.id,
        title: additionalService.name,
        price: !isPWSGarage ? additionalService.priceAfterDiscount : undefined,
      })
    })
  }

  return servicePrices
}
