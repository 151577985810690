import debounce from "lodash/debounce"
import { RefObject, useLayoutEffect, useState } from "react"

export type ElementSize = {
  width: number
  height: number
}

export type UseResize = (
  elementRef: RefObject<HTMLElement> | null,
) => ElementSize | undefined

export const useResize: UseResize = (elementRef) => {
  const [elementSize, setElementSize] = useState<ElementSize | undefined>()

  useLayoutEffect(() => {
    const element = !elementRef ? document.body : elementRef?.current

    if (!element) {
      return
    }

    const handleResize = debounce(() => {
      setElementSize({
        width: element.offsetWidth ?? 0,
        height: element.offsetHeight ?? 0,
      })
    }, 100)

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [elementRef])

  return elementSize
}
