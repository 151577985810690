import { FC, useEffect, useRef, useState } from "react"

import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { BookingService } from "src/helpers/autoservice-account-backend/types"
import { PriceCalculationComponents } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"

import { isPWSGarage } from "src/helpers/isPWSGarage"
import { useTranslation } from "src/hooks/translation"

import { useResize } from "src/hooks/useResize"

import { useServiceFlowData } from "src/states/serviceFlow"
import { DetailedGarage } from "src/states/serviceFlow/garageActions"

import { ServicePrice } from "src/states/serviceFlow/types"

import { InspectionService } from "./InspectionService"
import { Service } from "./Service"
import styles from "./ServiceSection.module.scss"
import { ServiceSectionPriceComponents } from "./ServiceSectionPriceComponents"

const bem = create(styles, "ServiceSection")

type ServiceSectionProps = {
  service?: BookingService
  garage?: DetailedGarage["garage"]
  isInspectionOfferFlow?: boolean
  priceCalculationComponents?: PriceCalculationComponents
  servicePrice?: ServicePrice
  isShoppingCart?: boolean
  canShowPrice?: boolean
}

export const ServiceSection: FC<ServiceSectionProps> = ({
  service,
  garage,
  isInspectionOfferFlow,
  priceCalculationComponents,
  servicePrice,
  isShoppingCart,
  canShowPrice,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.bookingSummaryCard.buttons

  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResize(containerRef)
  const [isDetailsButtonShown, setIsDetailsButtonShown] = useState(false)
  const [isDetailsShown, setIsDetailsShown] = useState(false)

  const { detailedGarage } = useServiceFlowData()
  const dmsProviderType =
    garage?.dmsProviderType ?? detailedGarage?.garage?.dmsProviderType
  const isPWS = isPWSGarage(dmsProviderType)

  useEffect(() => {
    // 93px is approximately 3 lines of text.
    // If the text is longer than 3 lines, we hide part of it and show More Details button.
    // we show all the details in the shopping cart by default.
    if (isShoppingCart) {
      return
    }

    if (containerSize?.height && containerSize.height > 93) {
      setIsDetailsButtonShown(true)
    }
  }, [containerSize?.height, isShoppingCart])

  if (!service && !isInspectionOfferFlow) {
    return null
  }

  function onDetailsButtonClick() {
    setIsDetailsShown(!isDetailsShown)
  }

  const isInspection = checkServiceType.isInspectionService(service?.id)
  const isInspectionRequestFlow =
    isInspection && !isInspectionOfferFlow && !service?.priceAfterDiscount

  return (
    <div
      className={bem()}
      data-cy={
        service?.icon ? `SummaryItemCard-${service?.icon}` : `SummaryItemCard`
      }
    >
      <div
        ref={containerRef}
        className={bem("container", {
          collapsed: isDetailsButtonShown && !isDetailsShown,
        })}
      >
        {isInspectionRequestFlow ? (
          <InspectionService title={service?.name} />
        ) : (
          <Service
            service={service}
            servicePrice={servicePrice}
            garage={garage ?? detailedGarage?.garage}
            isInspectionOfferFlow={isInspectionOfferFlow}
            canShowPrice={canShowPrice}
          />
        )}

        <ServiceSectionPriceComponents
          service={service}
          servicePrice={servicePrice}
          isPWSGarage={isPWS}
          priceCalculationComponents={priceCalculationComponents}
        />
      </div>

      {isDetailsButtonShown && (
        <BookingSummaryCardItem
          title={
            <Button
              variant="tertiary"
              data-cy={`ShowDetails-${service?.categoryId}`}
              className={bem("button")}
              onClick={onDetailsButtonClick}
            >
              {isDetailsShown
                ? translations.hideDetails
                : translations.showDetails}
              <Icon
                name="dropdownMenuDown"
                className={bem("icon", { up: isDetailsShown })}
              />
            </Button>
          }
        />
      )}
    </div>
  )
}
