import { FC } from "react"

import { BookingSummaryCard } from "src/components/modules/bookingSummaryCard"
import {
  BookingService,
  CarBookingInfo,
} from "src/helpers/autoservice-account-backend/types"
import { isExpressAppointmentSelected } from "src/helpers/isExpressAppointmentSelected"
import { useIsMounted } from "src/hooks/isMounted"
import { useTranslation } from "src/hooks/translation"
import { useIsOnlineAppointmentAvailable } from "src/hooks/useIsOnlineAppointmentAvailable"
import { useServiceFlowData } from "src/states/serviceFlow"
import { Garage } from "src/states/serviceFlow/garageActions"

import { AppointmentSummaryCardItem } from "./appointmentSummaryCardItem"
import { CarSummaryCardItem } from "./carSummaryCardItem"
import { GarageSummaryCardItem } from "./garageSummaryCardItem"

type AppointmentSectionProps = {
  services?: BookingService[]
  timeslot?: Date | null
  garage?: Garage
  car?: Partial<Omit<CarBookingInfo, "id">>
}

export const AppointmentSection: FC<AppointmentSectionProps> = ({
  services,
  timeslot,
  garage,
  car,
}) => {
  const isMounted = useIsMounted()
  const { messages } = useTranslation()
  const translations =
    messages.components.service.steps.summary.appointmentSelection

  const isOnlineAppointmentAvailable = useIsOnlineAppointmentAvailable(garage)
  const { serviceCategories } = useServiceFlowData()
  const servicesSelected = serviceCategories ?? services
  const isBooking = isOnlineAppointmentAvailable && !!timeslot

  const expressAppointmentSelected =
    isExpressAppointmentSelected(servicesSelected)

  // This component needs to be client-side because of the usage of localStorage data otherwise it throws hydration errors
  if (!isMounted) return null

  const getTitle = () => {
    if (expressAppointmentSelected) {
      return translations.appointment.express
    }

    if (isOnlineAppointmentAvailable) {
      return translations.appointment.online
    }

    return translations.appointment.offline
  }

  const title = getTitle()

  return (
    <BookingSummaryCard
      title={translations.appointment.title[isBooking ? "online" : "offline"]}
    >
      <AppointmentSummaryCardItem
        timeslot={timeslot}
        isExpressAppointmentSelected={expressAppointmentSelected}
        title={title}
      />

      <GarageSummaryCardItem garage={garage} />

      {car?.hsn && car?.tsn && <CarSummaryCardItem car={car} />}
    </BookingSummaryCard>
  )
}
