import { FC, Fragment } from "react"

import { ServicePrice } from "../servicePrice"
import { BookingSummaryCardItem } from "src/components/modules/bookingSummaryCard/bookingSummaryCardItem"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./Service.module.scss"

const bem = create(styles, "Service")

type InspectionServiceProps = {
  title?: string
}

export const InspectionService: FC<InspectionServiceProps> = ({ title }) => {
  const { messages } = useTranslation()
  const translations = messages.components.service.steps.summary.inspectionInfo

  return (
    <>
      <BookingSummaryCardItem
        title={title}
        icon="basisinspektion"
        variant="bold"
        rightInfo={<ServicePrice price={undefined} />}
      />
      <BookingSummaryCardItem
        title={
          <div className={bem("priceText")}>{translations.description}</div>
        }
        dataCy="SummaryItemCard-basisinspektion-description"
      />
    </>
  )
}
