import { FC, useState } from "react"

import { InquirySection } from "src/components/core/bookingSummaryCard/sections/inquiry/inquirySection"

import { ServiceInquiryModal } from "src/components/pages/services/flow/services/serviceInquiry/serviceInquiryForm/ServiceInquiryModal"

import { Inquiry } from "src/states/serviceFlow/inquiryActions"

export type InquirySummaryProps = {
  isShoppingCart?: boolean
  inquiry?: Inquiry
}

export const InquirySummary: FC<InquirySummaryProps> = ({
  isShoppingCart,
  inquiry,
}) => {
  const [openInquiryModal, setOpenInquiryModal] = useState(false)

  const hideSection = !inquiry?.message

  if (hideSection) return null

  return (
    <>
      <InquirySection
        openModal={() => setOpenInquiryModal(true)}
        inquiry={inquiry}
        isShoppingCart={isShoppingCart}
      />
      <ServiceInquiryModal
        open={openInquiryModal}
        inquiry={inquiry}
        canEdit={true}
        onOpenChange={() => setOpenInquiryModal(false)}
      />
    </>
  )
}
